body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

